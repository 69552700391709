<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true"
      :reg-id="$route.query.regId" />
    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <div class="row align-items-center">
                <div class="col-md-auto">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <h5 class="card-title font-weight-semibold">Asesmen Pra Induksi</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Rencana Pembedahan</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Tanggal Operasi<small class="txt_mandatory">*</small></label>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                  </div>
                                  <datepicker :disabled="true" v-model="row.arano_jadwal_operasi"
                                    input-class="form-control transparent" calendar-class="my-datepicker">
                                  </datepicker>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Dokter Operator<small class="txt_mandatory">*</small></label>
                                <div class="input-group">
                                  <input type="text" class="form-control" v-model="row.dokter_operator" disable
                                    readonly>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Kegawatan Operasi<small class="txt_mandatory">*</small></label>
                                <b-form-radio-group :options="Config.mr.ranapJenisOperasi"
                                  v-model="row.aranpio_rencana_tindakan" />
                                <VValidate name="Kegawatan Operasi" v-model="row.aranpio_rencana_tindakan"
                                  :rules="toValidate(mrValidation.aranpio_rencana_tindakan)" />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Diagnosa Awal<small class="txt_mandatory">*</small></label>
                                <div class="input-group">
                                  <input v-model="row.diagnosa" type="text" class="form-control"
                                    value="Nyeri dibagian perut" disable readonly>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Dokter Anastesi<small class="txt_mandatory">*</small></label>
                                <div class="input-group">
                                  <input type="text" class="form-control" v-model="row.dokter_anestesi" disable
                                    readonly>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Sifat Operasi<small class="txt_mandatory">*</small></label>
                                <div class="input-group">
                                  <b-form-input type="text" v-model="row.aranpio_sifat_operasi" class="form-control" />
                                </div>
                                <VValidate name="Sifat Operasi" v-model="row.aranpio_sifat_operasi"
                                  :rules="toValidate(mrValidation.aranpio_sifat_operasi)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Tekanan Darah </label>
                            <div class="input-group">
                              <b-form-input :formatter="number" v-model="row.aranpio_ttv_tekanan_darah_min" type="text"
                                name="name" class="form-control" placeholder="Systole" />

                              <div class="input-group-append input-group-prepend"><span
                                  class="input-group-text">/</span></div>

                              <b-form-input :formatter="number" placeholder="Diastole"
                                v-model="row.aranpio_ttv_tekanan_darah_max" type="text" class="form-control" />
                              <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>

                            <VValidate name="Tekanan Darah Min" v-model="row.aranpio_ttv_tekanan_darah_min"
                              :rules="toValidate(mrValidation.aranpio_ttv_tekanan_darah_min)" />
                            <VValidate name="Tekanan Darah Max" v-model="row.aranpio_ttv_tekanan_darah_max"
                              :rules="toValidate(mrValidation.aranpio_ttv_tekanan_darah_max)" />
                          </div>
                        </div>

                        <div class="col-md-5">
                          <div class="form-group">
                            <label>Nadi</label>
                            <div class="form-row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <b-form-input :formatter="number" v-model="row.aranpio_ttv_nadi" type="text"
                                    class="form-control" />
                                  <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                  <div class="input-group-append">
                                    <div style="width: 140px;">
                                      <v-select placeholder="Pilih Label" v-model="row.aranpio_ttv_label"
                                        :options="Config.mr.StatusRegular" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <VValidate name="Nadi" v-model="row.aranpio_ttv_nadi"
                                :rules="toValidate(mrValidation.aranpio_ttv_nadi)" />
                              <VValidate name="Label" v-model="row.aranpio_ttv_label"
                                :rules="toValidate(mrValidation.aranpio_ttv_label)" />
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-md-3">
                          <div class="form-row">
                            <label>GDA</label>
                            <div class="col-md-12">
                              <div class="input-group">
                                <b-form-input :formatter="alphanum" v-model="row.aranpio_ttv_gula_darah" type="text"
                                  class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <VValidate name="Gula Darah" v-model="row.aranpio_ttv_gula_darah"
                            :rules="toValidate(mrValidation.aranpio_ttv_gula_darah)" />
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Pernafasan<span class="text-danger">*</span></label>
                            <div class="input-group">
                              <b-form-input :formatter="number" v-model="row.aranpio_ttv_pernafasan" type="text"
                                class="form-control" />
                              <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            </div>

                            <VValidate name="Pernafasan" v-model="row.aranpio_ttv_pernafasan"
                              :rules="toValidate(mrValidation.aranpio_ttv_pernafasan)" />
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>SPO2</label>
                            <div class="form-row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <b-form-input :formatter="number" v-model="row.aranpio_ttv_spo2" type="text"
                                    class="form-control" />
                                  <div class="input-group-append"><span class="input-group-text">%</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <VValidate name="SPO2" v-model="row.aranpio_ttv_spo2"
                              :rules="toValidate(mrValidation.aranpio_ttv_spo2)" />
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Suhu<span class="text-danger">*</span></label>
                            <div class="form-row">

                              <div class="col-md-12">
                                <div class="input-group">
                                  <b-form-input :formatter="number" v-model="row.aranpio_ttv_suhu" type="text"
                                    class="form-control" />
                                  <div class="input-group-append"><span class="input-group-text">C</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <VValidate name="Suhu" v-model="row.aranpio_ttv_suhu"
                              :rules="toValidate(mrValidation.aranpio_ttv_suhu)" />
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Berat Badan</label>
                            <div class="form-row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <b-form-input :formatter="number" v-model="row.aranpio_ttv_weight" type="text"
                                    class="form-control" />
                                  <div class="input-group-append"><span class="input-group-text">kg</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <VValidate name="Berat Badan" v-model="row.aranpio_ttv_weight"
                              :rules="toValidate(mrValidation.aranpio_ttv_weight)" />
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Tinggi Badan</label>

                            <div class="form-row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <b-form-input :formatter="number" v-model="row.aranpio_ttv_height" type="text"
                                    class="form-control" />
                                  <div class="input-group-append"><span class="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <VValidate name="Tinggi Badan" v-model="row.aranpio_ttv_height"
                              :rules="toValidate(mrValidation.aranpio_ttv_height)" />
                          </div>
                        </div>


                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Status Mental</label>
                            <v-select placeholder="Pilih Salah Satu" v-model="row.aranpio_ttv_status_mental"
                              :options="Config.mr.mStatusMental" label="text" :clearable="true" :reduce="v=>v.value">
                            </v-select>
                          </div>
                        </div>

                        <div class="col-md-9">
                          <div class="form-group">
                            <label>ASA</label>
                            <v-select placeholder="Pilih Salah Satu" v-model="row.aranpio_ttv_asa"
                              :options="Config.mr.mAsaPraInduksi" label="text" :clearable="true" :reduce="v=>v.value">
                            </v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mb-2">
                    <div class="card mb-0">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Riwayat Penyakit Keluarga & Anestesi</h6>
                      </div>
                      <div class="card-body p-3 d-flex">
                        <div>
                          <b-form-checkbox-group id="riwayat" v-model="row.aranpio_riwayat"
                            :options="Config.mr.statusRiwayatKeluargaAnestesi" name="riwayat"></b-form-checkbox-group>
                        </div>

                        <div class="ml-3" v-if="(row.aranpio_riwayat||[]).indexOf('Lainnya') != -1">
                          <div class="form-group">
                            <div class="input-group">
                              <b-form-input type="text" v-model="row.aranpio_riwayat_lainnya" class="form-control" />
                            </div>
                            <VValidate name="Riwayat Lainnya" v-model="row.aranpio_riwayat_lainnya"
                              :rules="{required: 1, min: 3, max: 128}" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!--
                  <div class="col-12">
                      <div class="card mb-0">
                      <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
                      </div>
                      <div class="card-body p-3">
                          <div class="alert alert-info">Silakan pilih/ceklis bagian yang memiliki kelainan</div>
                          
                          <div class="bg-light card-header c-pointer"
                          @click="pemeriksaanFisik = !pemeriksaanFisik"
                          :class="pemeriksaanFisik ? null : 'collapsed'"
                          :aria-expanded="pemeriksaanFisik ? 'true' : 'false'"
                          aria-controls="GenPartRow">
                          <div class="d-flex align-items-center justify-content-between">
                              <span class="card-title font-weight-semibold">Klik untuk
                              {{ pemeriksaanFisik ? "Menyembunyikan" : "Menampilkan" }} Bagian Keadaan
                              Umum</span>
                              <i class="icon-arrow-down22" style="font-size: 20px;"></i>
                          </div>
                          </div>                
                          
                          <b-collapse id="GenPartRow" v-model="pemeriksaanFisik">
                              <div class="row g-0">
                                  <div class="col-md-6" v-for="(genCol, genColKey) in oddEvenGenCondition"
                                  :key="genColKey+'genCol'">
                                  <div class="gen-part" v-for="(vs,ks) in genCol" :key="ks+'sign'">
                                      <div
                                      class="d-flex align-items-center justify-content-between gen-part-head c-pointer"
                                      @click="row[vs.value+'isOpen'] = !row[vs.value+'isOpen']"
                                      :class="row[vs.value+'isOpen'] ? null : 'collapsed'">
                                      <div class="font-weight-semibold">{{vs.label}}
                                          <span class="text-danger" v-if="row[vs.value] == 'TN'">*</span>
                                      </div>
                                      <i class="icon-arrow-down22"></i>
                                      </div>

                                      <b-collapse :id="vs.value" v-model="row[vs.value+'isOpen']"
                                      accordion="accKeadaanUmum">
                                      <div class="gen-part-body mt-1">
                                          <div>
                                          <div class="custom-control custom-radio custom-control-inline">
                                              <input type="radio" v-model="row[vs.value]"
                                              @input="changeVital(vs.value,'N')"
                                              class="custom-control-input" :name="`formType${vs.label}`"
                                              :id="`normalCheck${vs.label}`" value="N">
                                              <label class="custom-control-label"
                                              :for="`normalCheck${vs.label}`">
                                              Normal
                                              </label>
                                          </div>
                                          <div class="custom-control custom-radio custom-control-inline">
                                              <input type="radio" v-model="row[vs.value]"
                                              @input="changeVital(vs.value,'TN')"
                                              class="custom-control-input" :name="`formType${vs.label}`"
                                              :id="`notNormalCheck${vs.label}`" value="TN">
                                              <label class="custom-control-label"
                                              :for="`notNormalCheck${vs.label}`">
                                              Tidak Normal
                                              </label>
                                          </div>
                                          <div class="custom-control custom-radio custom-control-inline">
                                              <input type="radio" v-model="row[vs.value]"
                                              class="custom-control-input" :name="`formType${vs.label}`"
                                              :id="`notCheck${vs.label}`" value="TP">
                                              <label class="custom-control-label"
                                              :for="`notCheck${vs.label}`">
                                              Tidak Diperiksa
                                              </label>
                                          </div>
                                          </div>
                                          <div class="gen-input mt-2" v-if="row[vs.value] !== 'TP'">
                                                  <template v-if="vs.value == 'arankaa_vs_saraf' && row[vs.value] == 'TN'">
                                                      <div class="gen-input-saraf" data-select2-id="162">
                                                      <div class="mb-2">
                                                          <label for="refleksFisiologis">Refleks Fisiologis</label>
                                                          <b-textarea
                                                          v-model="row.arankaa_vs_saraf_r_fisiologis" class="form-control"
                                                          rows="2"></b-textarea>
                                                      </div>
                                                      <div class="mb-2">
                                                          <label for="refleksPatologis">Refleks Patologis</label>
                                                          <b-textarea
                                                          v-model="row.arankaa_vs_saraf_r_patologis" class="form-control"
                                                          rows="2"></b-textarea>
                                                      </div>

                                                      <div class="row g-1">
                                                          
                                                          <div class="col-md-6">
                                                              <label>Babinski</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_babinski" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <label>Lasegue</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_lasegue" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>
                                                          
                                                          <div class="col-md-6">
                                                              <label>Bregard</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_bregard" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>
                                                          
                                                          <div class="col-md-6">
                                                              <label>Sicard</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_sicard" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>
                                                          
                                                          <div class="col-md-6">
                                                              <label>Kaku Duduk</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_kaku_duduk" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <label>Kernig Sign</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_kernig_sign" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <label>Brudzinski 1</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_brudzinski" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>
                                                          
                                                          <div class="col-md-6">
                                                              <label>Brudzinski 2</label>
                                                              <v-select placeholder="Status" 
                                                              v-model="row.arankaa_vs_saraf_brudzinski2" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>

                                                      </div>
                                                      
                                                      </div>
                                                  </template>
                                                  <template v-else>
                                                      <b-textarea
                                                          v-model="row[vs.value+'_text']" class="form-control"
                                                          :placeholder="`cth. pembengkakan pada bagian ${vs.label} sebelah kiri`"
                                                          rows="2"></b-textarea>

                                                      <VValidate :name="`${vs.label}`" v-model="row[vs.value+'_text']"
                                                          :rules="{required:1, min:2, max:512}" />
                                                  </template>
                                          </div>
                                      </div>
                                      </b-collapse>
                                  </div>
                                  </div>
                              </div>
                          </b-collapse>

                      </div>
                      </div>
                  </div>
                  -->
                  <div class="row p-3">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="perHub">Paru-paru</label>
                        <b-form-textarea v-model="row.aranpio_ku_paru" rows="3" class="form-control"></b-form-textarea>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="perHub">Jantung</label>
                        <b-form-textarea v-model="row.aranpio_ku_jantung" rows="3" class="form-control"></b-form-textarea>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="perHub">Abdomen</label>
                        <b-form-textarea v-model="row.aranpio_ku_abdomen" rows="3" class="form-control"></b-form-textarea>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="perHub">Ekstremitas</label>
                        <b-form-textarea v-model="row.aranpio_ku_ekstremitas" rows="3" class="form-control"></b-form-textarea>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="perHub">Neurologi (bila bisa diperiksa)</label>
                        <b-form-textarea v-model="row.aranpio_ku_neuorologi" rows="3" class="form-control"></b-form-textarea>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="perHub">Keterangan</label>
                        <b-form-textarea v-model="row.aranpio_ku_keterangan_ttv" rows="3" class="form-control"></b-form-textarea>
                      </div>
                    </div>
                  </div>
                        
                  <div class="row">
                    <div class="col-6" v-if="Object.keys(dataPenunjang||{}).length">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
                        </div>
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6>Hasil Pemeriksaan Lab</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilLab||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilLab||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <h6>Hasil Pemeriksaan Radiologi</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologi||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilRadiologi||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <h6>Hasil Pemeriksaan Lab Dokter</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilLabDokter||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilLabDokter||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                        <thead>
                                        <tr>
                                            <th>Hasil Pemeriksaan </th>
                                            <th>Hasil</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                            <td>{{v.value}}</td>
                                            <td>
                                                <div class="result_tab form-group mb-0">
                                                <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataPenunjang.dataHasilRadiologiDokter||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="col-6 mb-2">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Problem dan Konsultasi</h6>
                        </div>
                        <div class="card-body p-3">
                          <b-form-textarea v-model="row.aranpio_problem_konsultasi" name="ProblemdanKonsultasi" id="ProblemdanKonsultasi" rows="6" class="form-control"
                            placeholder=""></b-form-textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-8">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Premedikasi</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Tanggal & Jam<small class="txt_mandatory">*</small></label>
                                <div>
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                    </div>
                                    <datepicker v-model="row.aranpio_premedikasi_tanggal"
                                      input-class="form-control transparent" calendar-class="my-datepicker">
                                    </datepicker>
                                    
                                    <div class="input-group-prepend ml-3"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranpio_premedikasi_jam">
                                    </vue-timepicker>

                                  </div>
                                </div>
                                
                                <VValidate name="Tanggal" v-model="row.aranpio_premedikasi_tanggal"
                                  :rules="{required: 1}" />
                                  
                                <VValidate name="Jam" v-model="row.aranpio_premedikasi_jam"
                                  :rules="{required: 1}" />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                  <tr>
                                    <th>No </th>
                                    <th>Nama Obat</th>
                                    <th>Keterangan</th>
                                    <th>Aksi</th>
                                  </tr>
                                </thead>
                                
                                <tbody>
                                  <tr v-for="(v,k) in (row.aranpio_premedikasi_obat||[])" :key="k+'obat'">
                                    <td>{{k+1}}</td>
                                    <td>
                                      <v-select style="flex: 1;" class="med-selection select-paging"
                                        placeholder="Pilih Item"
                                        :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                                        @open="countLen" @search="filterObat($event,k,'aranpio_premedikasi_obat');countLen();"
                                        v-model="row.aranpio_premedikasi_obat[k].item_id" :options="mObatPreferensi"
                                        label="text" @input="selectJumlahSatuan($event,k,'aranpio_premedikasi_obat')" :clearable="true"
                                        :reduce="v=>v.value"
                                        :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                        <template slot="selected-option">
                                        <span
                                            :class="isAlergiLabel(v.nama)?'text-warning':''">{{ v.nama }}</span>
                                        </template>
                                        <template slot="option" slot-scope="option">
                                        <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                            v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                        <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                            v-else>{{ option.text }}</span>
                                        </template>
                                        <template #list-footer>
                                        <li class="list-paging">
                                            <div class="d-flex align-items-center justify-content-between">
                                            <b-button
                                                @click="changePageObat(v.currentPage,k,'min','aranpio_premedikasi_obat')"
                                                size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                <i class="icon-arrow-left22 text-white"></i></b-button>
                                            <small>Halaman {{v.currentPage}} dari
                                                {{ceilData(v.totalRows/10)}}</small>
                                            <b-button
                                                @click="changePageObat(v.currentPage,k,'plus','aranpio_premedikasi_obat')"
                                                size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                <i class="icon-arrow-right22 text-white"></i></b-button>
                                            </div>
                                        </li>
                                        </template>
                                    </v-select>
                                    </td>
                                    <td>
                                      <textarea  v-model="row.aranpio_premedikasi_obat[k].ket" class="form-control" rows="3"></textarea>
                                    </td>
                                    <td>
                                      <a href="javascript:;" @click="removeObat(k,'aranpio_premedikasi_obat')"
                                      class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                                      class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                
                                <tfoot>
                                  <tr>
                                      <td class="text-center" colspan="99">
                                      <a href="javascript:;" @click="openObat('aranpio_premedikasi_obat')" class="btn alpha-blue">
                                        <i class="icon-plus2 mr-1 align-middle"></i>
                                        <span class="align-middle">Tambah Resep</span>
                                      </a>
                                      </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Persiapan Khusus</h6>
                        </div>
                        <div class="card-body p-3">
                          <b-form-textarea v-model="row.aranpio_persiapan_khusus" name="PersiapanKhusus" id="PersiapanKhusus" rows="6" class="form-control"
                            placeholder=""></b-form-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="text-right">
                  <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                  <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>

    </div>
  </div>
</template>



<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Datepicker from 'vuejs-datepicker'
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  const moment = require('moment')
  import {
    stringSimilarity
  } from "string-similarity-js"
  import html2canvas from 'html2canvas'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'

  export default {
    extends: GlobalVue,
    data() {
      return {
        rowReg: {},
        menuPage: 'RanapPraInduksiOperasi',
        dataPenunjang: {},
        isEraserEnabled: false,

        mKananKiri: [{
            value: "Kanan",
            text: "Kanan",
          },
          {
            value: "Kiri",
            text: "Kiri",
          }
        ],

        mObatPreferensi: [],
        obatLen: 0,
        totalRows: 0,

        options: {
          penColor: "#000",
          backgroundColor: 'rgba(0,0,0,0)',
        },
        hexColor: "#000",
        openFisiologis: false,

        patientData: {},
        loading: {
          patientInfo: false,
        },
        pemeriksaanFisik : false,
      }
    },


    computed:{
      oddEvenGenCondition() {
          let odd = this.Config.mr.configVitalSignRanapPraInduksi.filter((el, index) => index % 2)
          let even = this.Config.mr.configVitalSignRanapPraInduksi.filter((el, index) => !(index % 2))
          let res = []
          res.push(even)
          res.push(odd)
          return res
      },
    //   distanceAnestesi(){
    //     let startDate = moment(moment(this.row.aranla_anastesi_date_start).format("YYYY-MM-DD") + ' ' + this.row.aranla_anastesi_jam_start)
    //     let endDate = moment(moment(this.row.aranla_anastesi_date_end).format("YYYY-MM-DD") + ' ' + this.row.aranla_anastesi_jam_end)
    //     let durationInMinutes = endDate.diff(startDate, 'minutes')

    //     if (isNaN(durationInMinutes)) {
    //         return '0 Menit'
    //     } else {
    //         let hours = Math.floor(durationInMinutes / 60)
    //         let minutes = durationInMinutes % 60
    //         return hours + ' Jam ' + minutes + ' Menit'
    //     }

    //   },
    //   distanceOperasi(){
    //     let startDate = moment(moment(this.row.aranla_operasi_date_start).format("YYYY-MM-DD") + ' ' + this.row.aranla_operasi_jam_start)
    //     let endDate = moment(moment(this.row.aranla_operasi_date_end).format("YYYY-MM-DD") + ' ' + this.row.aranla_operasi_jam_end)
    //     let durationInMinutes = endDate.diff(startDate, 'minutes')

    //     if (isNaN(durationInMinutes)) {
    //         return '0 Menit'
    //     } else {
    //         let hours = Math.floor(durationInMinutes / 60)
    //         let minutes = durationInMinutes % 60
    //         return hours + ' Jam ' + minutes + ' Menit'
    //     }

    //   }
    },
    components: {
      Datepicker,
      VueTimepicker,
      PatientInfo
    },
    mounted() {
      this.apiGet()

      this.getPatientInfo()
    },
    methods: {
      //contentToConvert
      back() {
        this.$router.back()
      },
      
      changeVital(field, n) {
          if (n == 'N') {
              this.row[field + '_text'] = 'TAK'
          } else {
              this.row[field + '_text'] = ''
          }
      },

      toValidate(val) {
        return {
          ...val
        }
      },

      apiGet(params = {}, page = 1) {
        if (!this.pageSlug || !this.$route.query.regId) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })

        // master
        if (this.$route.query.regId) {
          Gen.apiRest(
            "/get/" + url + '?master=1', {
              params: Object.assign({
                page: page
              }, paramsQuery, params.query || {})
            }
          ).then(res => {
            console.log(res.data)
            _.forEach(res.data, (v, k) => {
              this.$set(this, k, v)
            })
          })
        }

      },

      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.query.regId
            }
          },
          "POST"
        ).then(resp => {
          this.$set(this, 'patientData', resp.data.data)

          this.$set(this.loading, 'patientInfo', false)
        })
      },

      getConfigDynamic(master, value) {
        let text = ''
        if (value) {
          let index = (master || []).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index]['text']
          }
        }
        return text
      },

      autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if (!data.isEdit) {
          Gen.apiRest(
            "/do/" + this.menuPage, {
              data: data
            },
            "POST"
          )
        }
      }, 1000),


      doSubmit() {
        this.$refs['VForm'].validate().then(success => {
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                  console.log(el)
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }

                  if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }, 500)
              }
            })
          }
          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.row
                data.type = 'submit-data'

                this.loadingOverlay = true
                Gen.apiRest(
                  "/do/" + this.menuPage, {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.loadingOverlay = false

                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.$router.push({
                      name: 'RanapOperasiDokterAnestesi'
                    }).catch(() => {})
                  })
                }).catch(err => {
                  this.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })
              }
            })
          }
        })
      },


      //obat

      selectJumlahSatuan(e, k, col) {
        let data = {
          id: e,
          type: 'get-by-obat'
        }
        Gen.apiRest(
          "/do/" + 'RanapCPPTDokter', {
            data: data
          },
          "POST"
        ).then(res => {
          this.row[col][k].nama = res.data.nama
          console.log(this.row[col][k].nama)
        })
      },
      ceilData(value) {
        return Math.ceil(value)
      },
      countLen() {
        setTimeout(() => {
          this.obatLen = $('.sp-col ul li').length
        }, 100)
      },
      isAlergiLabel(nama) {
        let data = []
        for (let i = 0; i < (this.row.ap_alergi || []).length; i++) {
          if (this.row.ap_alergi[i]['jenis'] == 'Obat') {
            data.push(this.row.ap_alergi[i]['name'])
          }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
          let result = stringSimilarity(nama || "", data[i])
          if (result > 0.3) isWarning += 1
        }
        return isWarning
      },
      changePageObat(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this.row[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this.row[col][k]['totalRows'] / 10)) {
            page += 1
            this.row[col][k]['currentPage'] += 1
          }
        }

        let data = {
          type: 'select-paging-obat',
          page: page,
          idDokter: this.rowReg.aranr_dokter_id,
          search: this.row[col][k]['search']
        }
        Gen.apiRest(
          "/do/" + 'RanapCPPTDokter', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mObatPreferensi = res.data.data
        })
      },

      filterObat: _.debounce(function (e, k, col) {
        if (e) {
          this.row[col][k]['search'] = e
          let id = []
          for (let i = 0; i < (this.row[col] || []).length; i++) {
            if (this.row[col][i]['item_id']) {
              id.push(this.row[col][i]['item_id'])
            }
          }
          Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
              type: 'select-obat',
              search: e,
              id: id
            }
          }, 'POST').then(res => {
            this.mObatPreferensi = res.data.data
            this.row[col][k]['currentPage'] = 1
            this.row[col][k]['totalRows'] = res.data.totalRows
          })
        }
      }, 10),

      openObat(col) {
        let data = {
          nama: null,
          item_id: null,
          ket: null,
          currentPage: 1,
          totalRows: this.totalRows
        }
        this.row[col].push(data)
      },

      removeObat(k, col) {
        this.row[col].splice(k, 1)
      },

      //obat
    },
    watch: {
      row: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
    }
  }
</script>