<template>
  <div>
    <validation-observer ref="VFormUtama">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-sm table-bordered">
            <thead>
                <tr>
                <th>Nama Tindakan</th>
                <th>Status</th>
                <th>Aksi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in isParent.rowEdit.arano_inform_consent_anestesi||[]" :key="k">
                    <td style="width:50%;">
                        <!--
                        <b-form-input type="text" class="form-control" v-model="v.nama_tindakan"/>
                        -->
                        <v-select 
                        :options="getMasterAnestesi(isParent.rowEdit.arano_inform_consent_anestesi,k)"
                        v-model="v.nama_tindakan"
                        placeholder="-- Pilih Salah Satu --"
                        label="text"
                        value="text"
                        :clearable="true" :reduce="v=>v.text"
                        >
                        </v-select>

                        <VValidate :name="'Nama Tindakan #'+(k+1)" v-model="v.nama_tindakan"
                            :rules="{required: 1}" />
                            
                        <VValidate :name="'Tindakan Inform Consent #'+(k+1)" v-model="v.id_tindakan"
                            :rules="{required: 1}" />
                            
                    </td>
                    <td style="width:20%">
                        <span class="badge badge-danger mt-1" v-if="v.is_cancel">Dibatalkan</span>
                        <span class="badge badge-success mt-1" v-else>Aktif</span>
                    </td>
                    <td style="width:30%;">
                        <a href="javascript:;" @click="openInformConsentForm(v,k)" class="btn btn-icon rounded-round btn-sm alpha-info border-info mt-1 mb-1" v-b-tooltip.hover title="Isi Form Inform Consent">
                        <i class="icon-file-plus"></i>
                        </a>
                        
                        <!-- Hapus hanya dilakukan ketika dia > 1 -->
                        <template v-if="(isParent.rowEdit.arano_inform_consent_anestesi||[]).length > 1">
                        <!--
                        <a href="javascript:;" v-if="v.id_tindakan && totalTindakanAktif > 1" @click="deleteInformConsent(v,k)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger mt-1 mb-1" v-b-tooltip.hover title="Hapus Tindakan Anestesi">
                        <i class="icon-bin"></i>
                        </a>
                        -->
                        
                        <a href="javascript:;" v-if="!v.id_tindakan" @click="isParent.rowEdit.arano_inform_consent_anestesi.splice(k,1)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger mt-1 mb-1" v-b-tooltip.hover title="Hapus Tindakan">
                        <i class="icon-bin"></i>
                        </a>
                        </template>
                        
                        <a href="javascript:;" v-if="v.id_tindakan" @click="openInformConsentDetailForm(v)" class="btn btn-icon rounded-round btn-sm alpha-success border-success mt-1 mb-1" v-b-tooltip.hover title="Lihat Inform Consent">
                        <i class="icon-file-eye"></i>
                        </a>

                        
                        <a href="javascript:;" v-if="v.id_tindakan && totalTindakanAktif > 1" @click="cancelInformConsent(v,k)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger mt-1 mb-1" v-b-tooltip.hover title="Batalkan Tindakan ">
                        <i class="icon-notification2"></i>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" class="text-center">
                        <button @click="addInformConsent" class="btn btn-info text-center" type="button">Tambah Tindakan</button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
    </validation-observer>

    
    <validation-observer ref="VFormInformconsent">
    <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
    <table class="table table-bordered table-sm table-input">
        <thead>
        <tr>
            <td colspan="3">
            <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
            </div>
            </td>
        </tr>
        <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1</td>
            <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>7</td>
            <td>Risiko</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td class="input-cell">
            <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
        </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div>
                <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                <div>
                    <b-form-radio-group
                        :options="Config.mr.pasienSetuju"
                        v-model="rowIC.aranic_is_pasien_setuju"
                    />
                    <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                </div>
                </div>
            </td>
            <td width="50%">
                <div>
                <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                <div>
                    <b-form-radio-group
                        :options="Config.mr.persetujuanTindakan"
                        v-model="rowIC.aranic_persetujuan_tindakan"
                    />
                    <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                </div>
                </div>
            </td>
            </tr>
        </tfoot>
    </table>
    </b-modal>
    </validation-observer>

    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
      <table class="table table-bordered table-sm table-input">
      <thead>
          <tr>
          <td colspan="3">
              <div class="result_tab">
                  <h4>Nama Tindakan / ICD-9-CM</h4>
                  <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
              </div>
          </td>
          </tr>
          <tr>
          <th width="24">No.</th>
          <th>Jenis Informasi</th>
          <th>Isi Informasi</th>
          </tr>
      </thead>
      <tbody>
          <tr>
          <td>1</td>
          <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
          <td>
          {{rowIC.aranic_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>2</td>
          <td>Dasar Diagnosis</td>
          <td>
          {{rowIC.aranic_dasar_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>3</td>
          <td>Tindakan Kedokteran</td>
          <td>
          {{rowIC.aranic_tindakan_dokter||"-"}}
          </td>
          </tr>
          <tr>
          <td>4</td>
          <td>Indikasi Tindakan</td>
          <td>
          {{rowIC.aranic_indikasi_tindakan||"-"}}
          </td>
          </tr>
          <tr>
          <td>5</td>
          <td>Tata Cara</td>
          <td>
          {{rowIC.aranic_tata_cara||"-"}}
          </td>
          </tr>
          <tr>
          <td>6</td>
          <td>Tujuan</td>
          <td>
          {{rowIC.aranic_tujuan||"-"}}
          </td>
          </tr>
          <tr>
          <td>7</td>
          <td>Risiko</td>
          <td>
          {{rowIC.aranic_risiko||"-"}}
          </td>
          </tr>
          <tr>
          <td>8</td>
          <td>Komplikasi</td>
          <td>
          {{rowIC.aranic_kompilasi||"-"}}
          </td>
          </tr>
          <tr>
          <td>9</td>
          <td>Prognosis</td>
          <td>
          {{rowIC.aranic_prognosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>10</td>
          <td>Alternatif & Risiko</td>
          <td>
          {{rowIC.aranic_alternatif||"-"}}
          </td>
          </tr>
          <tr>
          <td>11</td>
          <td>Lain-lain</td>
          <td>
          {{rowIC.aranic_lainnya||"-"}}
          </td>
          </tr>
      </tbody>
      <tfoot>
          <tr>
          <td width="50%" colspan="2">
              <div class="result_tab">
              <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
              <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
              <p v-else>Tindakan ditolak</p>
              </div>
          </td>
          <td width="50%">
              <div class="result_tab">
              <h4>Persetujuan tindakan diberikan kepada</h4>
              <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
              <p v-else>Penanggung Jawab</p>
              </div>
          </td>
          </tr>
      </tfoot>
      </table>
    </b-modal>

  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default {
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    totalTindakanAktif(){
        let total = 0
        for (let i = 0; i < (this.isParent.rowEdit.arano_inform_consent_anestesi||[]).length; i++) {
            if(this.isParent.rowEdit.arano_inform_consent_anestesi[i]['id_tindakan']){
                total = total + 1
            }
        }
        return total
    }
  },
  data(){
    return {
      rowIC: {},
      openModalIC: false,
      openModalICDetail: false,
    }
  },
  mounted() {
    console.log(this.isParent.rowEdit)
  },
  methods: {

    openInformConsentDetailForm(row){
        Gen.apiRest('/do/' + 'RanapTindakanLaboratorium', {
            data: {
                type: 'get-data-ic-single',
                id: row.id_tindakan,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.mi9_name = row.nama_tindakan
            this.rowIC.mi9_code = ''
            this.openModalICDetail = true                   
        })
    },
    openInformConsentForm(row,key){
      if(!row.id_tindakan){
          this.rowIC.aranic_diagnosis = null
          this.rowIC.aranic_id = null
          this.rowIC.aranic_dasar_diagnosis = null
          this.rowIC.aranic_tindakan_dokter = null
          this.rowIC.aranic_indikasi_tindakan = null
          this.rowIC.aranic_tata_cara = null
          this.rowIC.aranic_tujuan = null
          this.rowIC.aranic_risiko = null
          this.rowIC.aranic_kompilasi = null
          this.rowIC.aranic_prognosis = null
          this.rowIC.aranic_alternatif = null
          this.rowIC.aranic_lainnya = null
          this.rowIC.mi9_name = row.nama_tindakan
          this.rowIC.mi9_code = ''
          this.rowIC.key = key
      } else{
          Gen.apiRest('/do/' + "RanapTindakanLaboratorium", {
              data: {
                  type: 'get-data-ic-single',
                  id: row.id_tindakan,
              }
          }, 'POST').then(res => {
              this.rowIC = res.data.row 
              this.rowIC.mi9_name = row.nama_tindakan 
              this.rowIC.key = key
          })
      }

      this.openModalIC = true
    },

    submitInformConcent(){
      this.$refs['VFormInformconsent'].validate().then(success => {
        if(success){
            this.openModalIC = false

            let data = this.rowIC
            data.type = 'submit-data-inform-consent'
            data.aranic_aranr_id = this.isParent.rowEdit.aranr_id
            data.aranic_name = this.rowIC.mi9_name + (this.rowIC.mi9_code ? " ("+this.rowIC.mi9_code+")" : "")
            data.arano_id = this.isParent.rowEdit.arano_id

            data.arano_inform_consent_anestesi = this.isParent.rowEdit.arano_inform_consent_anestesi
            
            this.loadingOverlay = true
            Gen.apiRest(
            "/do/" + 'RanapOperasiDokterAnestesi', {
                data: data
            },
            "POST"
            ).then(res => {
                this.rowIC.aranic_id = null
                this.loadingOverlay = false
                this.isParent.rowEdit.arano_inform_consent_anestesi = res.data
                this.isParent.apiGet()
                return this.$swal({
                    title: "Data Informed Consent berhasil disimpan",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            })
        }
      })
    },

    deleteInformConsent(row,k){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan menghapus data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          
          let data = {
            type: "delete-data-inform-consent",
            aranic_id: row.id_tindakan,
            arano_id: this.isParent.rowEdit.arano_id
          }
          
          this.isParent.rowEdit.arano_inform_consent_anestesi.splice(k,1)

          data.arano_inform_consent_anestesi = this.isParent.rowEdit.arano_inform_consent_anestesi

          this.loadingOverlay = true
          Gen.apiRest(
          "/do/" + 'RanapOperasiDokterAnestesi', {
              data: data
          },
          "POST"
          ).then(res => {
              this.loadingOverlay = false
              this.rowIC.aranic_id = null
              this.isParent.apiGet()
              return this.$swal({
                  title: "Data Tindakan Anestesi berhasil dihapus",
                  icon: 'success',
                  confirmButtonText: 'Ok'
              })
          })
        }
      })
    },

    addInformConsent(){
        this.$refs['VFormUtama'].validate().then(success => {
            if(success){
                this.isParent.rowEdit.arano_inform_consent_anestesi.push({
                    // nama_tindakan : 'Tindakan Anestesi #' + lenIc,
                    nama_tindakan : null,
                    id_tindakan : null,
                    is_cancel : false,
                })
            }
        })
    },

    cancelInformConsent(v,k){
        // console.log(v,k)
        this.$swal({
            icon: 'warning',
            title: 'Batalkan Tindakan Anestesi?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
        }).then(result => {
            if(result.value){
            let data = {
                type: "cancel-inform-consent",
                arano_id: this.isParent.rowEdit.arano_id
            }
            this.isParent.rowEdit.arano_inform_consent_anestesi[k]['is_cancel'] = true 
            data.arano_inform_consent_anestesi = this.isParent.rowEdit.arano_inform_consent_anestesi

            this.loadingOverlay = true
            Gen.apiRest(
            "/do/" + 'RanapOperasiDokterAnestesi', {
                data: data
            },
            "POST"
            ).then(res => {
                this.loadingOverlay = false
                this.rowIC.aranic_id = null
                this.isParent.apiGet()
                return this.$swal({
                    title: "Data Tindakan Anestesi berhasil dibatalkan",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            })
            }
        })
    },

    
    getMasterAnestesi(data,k){
        const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
        return k2 != k
        }), v2 => {
            return v2.nama_tindakan
        })
        return _.filter(this.Config.mr.tindakanAnestesi, v2 => {
            return sldMenu.indexOf(v2.text) <= -1
        })
    },
  },
}
</script>

