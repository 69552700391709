<template>
	<div class="content-wrapper" v-if="isList">
		<bo-page-title />
		<div class="content pt-0">
			<div class="card">
				<div class="bg-white card-header">
					<div class="row align-items-center">
						<div class="col-md-4 col-lg-5">
							<div class="form-row align-items-center">
								<div class="col-lg-4">
									<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
								</div>
								<div class="col-lg-8">
									<div class="form-group mb-0">
										<div class="input-group">
											<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
												:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
												:ranges="datePickerConfig.ranges" @update="updateValues">
												<template v-slot:input="picker">
													{{ picker.startDate | date }} - {{ picker.endDate | date }}
												</template>
											</date-range-picker>
											<div class="input-group-append calendar-group">
												<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card-header">
					<div class="row">
						<div class="col-md-8 col-lg-9">
							<div class="row gx-1">
								<div class="col-md-auto">
									<div class="form-group mb-0">
										<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField"
											data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i
												class="icon-spinner11"></i></button>
									</div>
								</div>
								<div class="col-md">
									<div class="row g-1">
										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select disabled placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"
													:options="mDPJP" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>
										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status"
													:options="mOperasiStatus" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
								<input class="form-control" v-model="filter.search" @input="doSearch"
									placeholder="Ketik Nama/No. RM Pasien" />
								<div class="form-control-feedback">
									<i class="icon-search4 text-indigo"></i>
								</div>

								<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
									@click="doResetData()">Reset</b-button>
							</div>
						</div>
					</div>
				</div>



				<div class="table-responsive">
					<table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
						<thead>
							<tr>
								<th>NO.</th>
								<th class="tbl-sticky-col tsc-left">NO. KEDATANGAN</th>
								<th class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">NAMA/NO KTP</th>
								<th>DOKTER DPJP</th>
								<th>NO RM</th>
								<th>TANGGAL LAHIR</th>
								<th>USIA</th>
								<th>TINDAKAN OPERASI</th>
								<th>TINDAKAN ANESTESI</th>
                <th>DIAGNOSA</th>
								<th>JADWAL OPERASI</th>
								<th>DOKTER OPERATOR</th>
								<th>DOKTER ANESTESI</th>
								<th>PERAWAT ANESTESI</th>
								<th>STATUS</th>
								<th style="min-width: 100px;" class="text-center tbl-sticky-col tsc-right tsc-right-first">AKSI</th>
							</tr>
						</thead>

						<tbody v-if="(dataList||[]).length">
							<template v-for="(v,k) in (dataList||[])">
								<tr :key="k" :class="!v.aranr_ppjp && !v.aranr_perawat_pelaksana ? 'table-warning':''">
									<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td class="tbl-sticky-col tsc-left">
										<div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
									</td>
									<td class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">
										<div>
											<a href="javascript:;" @click="toDetail(v.aranr_id)" class="font-weight-semibold border-bottom"
												v-b-tooltip.hover title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
											<br>
											{{v.ap_code||"-"}}</div>
										<small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
									</td>
									<td>{{v.bu_full_name||"-"}}</td>
									<td>
										<div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
									</td>
									<td>
										<div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
										<span v-else> - </span>
									</td>
									<td>
										<div class="font-weight-semibold">
											{{v.ap_usia_with_ket || "-"}}
										</div>
									</td>
									<td>
										{{v.arano_tindakan_operasi||"-"}}
									</td>
									<td>{{v.arano_jenis_new||v.arano_jenis||"-"}}</td>
                  <td>
                    {{v.arano_diagnosa||"-"}}                  
                  </td>
									<td>
										{{v.arano_jadwal_operasi | moment("DD MMMM YYYY, HH:mm")}} WIB
									</td>
									<td>
										<span v-if="!(v.dokter_operator||[]).length"> - </span>
										<ul v-else>
											<li v-for="(vk,kk) in (v.dokter_operator||[])" :key="kk+'s'">{{vk}}</li>
										</ul>
									</td>
									<td>
										{{v.dokter_anastesi||"-"}}
									</td>
									<td>
										{{v.perawat_anestesi||"-"}}
									</td>
									<td>
										<div :class="`badge ${v.aranos_color} mb-1`">{{v.aranos_name||"-"}}</div>
										<div v-if="v.arano_is_implant == 'Y'" :class="`badge bg-danger mb-1`">Implant</div>
										<div v-if="v.arano_is_implant == 'B'" v-b-tooltip.hover :title="v.arano_implant_cancel_reason" :class="`badge bg-danger mb-1`">Implant Dibatalkan</div>
										<div v-if="v.arano_is_penyakit_menular == 'Y'" :class="`badge bg-danger mb-1`" v-b-tooltip.hover
											:title="v.mranpm_name">Penyakit Menular</div>
										<div v-if="v.arano_isi_ubs == 'N'" :class="`badge bg-primary mb-1`">Pasien Pindah Ruang</div>
									</td>
									<td class="tbl-sticky-col tsc-right tsc-right-first">
										<a href="javascript:;" @click="toDetail(v.aranr_id)" v-if="moduleRole('view')"
											class="btn btn-icon rounded-round btn-sm alpha-info border-info" v-b-tooltip.hover
											title="Lihat Detail">
											<i class="icon-file-eye"></i>
										</a>

										<a href="javascript:;" @click="toInformConsentInput(v)" v-if="moduleRole('inform_consent')"
											class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800"
											v-b-tooltip.hover.noninteractive title="Ubah Tindakan Anestesi & Inform Consent">
											<i class="icon-notification2"></i>
										</a>

										<template v-if="v.arano_status != 99">
										<a href="javascript:;" v-if="moduleRole('jadwal_operasi') && v.arano_status < 4"
											@click="jadwalkanOperasi(v)"
											class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800 mt-1"
											v-b-tooltip.hover.noninteractive
											:title="v.arano_status >= sOpTerjadwal ? 'Ubah Jadwal Operasi' : 'Jadwalkan Operasi'">
											<i class="fa-solid fa-calendar"></i>
										</a>


										<a href="javascript:;" @click="doOpenKajianOperasi(v)"
											class="btn btn-icon rounded-round btn-sm alpha-info border-info mt-1 mb-1" v-b-tooltip.hover
											title="Isi Form Operasi">
											<i class="icon-file-plus"></i>
										</a>
										</template>

									</td>

								</tr>
							</template>
						</tbody>
						<tbody v-if="data.data.length == 0">
							<tr>
								<td colspan="99">
									<div class="text-center">
										<h4 align="center"><span
												v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
												tidak ditemukan</span>
											<span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!data.data">
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
						</tbody>
					</table>


					<div
						:class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
						<a href="javascript:;" data-scroll="left"
							class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
							<i class="icon-chevron-left"></i>
						</a>
						<a href="javascript:;" data-scroll="right"
							class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
							<i class="icon-chevron-right"></i>
						</a>
					</div>
				</div>

				<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
					<b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
				</b-card-footer>

				<b-modal v-model="openKajianOperasi" id="KajianOperasi" size="md" centered title="Kajian Operasi" hide-footer>
					<div class="row">
						<div class="col-md-6 mb-2" v-if="moduleRole('kajian_pra_anestesi')">
							<a href="javascript:;" @click="toMenu(rowEdit,'KajianPraAnestesi')" class="btn_cta_persalinan">
								{{rowEdit.arano_done_pra_anestesi == 'Y' ? 'Ubah ':'Buat '}} Kajian Pra Anestesi </a>
						</div>
						<div class="col-md-6 mb-2" v-if="moduleRole('ceklist_keselamatan_pasien')">
							<a href="javascript:;" @click="toMenu(rowEdit,'RanapCeklistKeselamatanAnestesi')"
								class="btn_cta_persalinan">
								{{rowEdit.arano_done_checklist_keselamatan == 'Y' ? 'Ubah ':'Buat '}} Checklist Keselamatan Pasien </a>
						</div>
						<div class="col-md-6 mb-2" v-if="moduleRole('ceklist_keselamatan_anestesi')">
							<a href="javascript:;" @click="toMenu(rowEdit,'RanapCeklistAnestesi')" class="btn_cta_persalinan">
								{{rowEdit.arano_done_checklist_anestesi == 'Y' ? 'Ubah ':'Buat '}} Checklist Keamanan Anestesi </a>
						</div>
						<div class="col-md-6 mb-2" v-if="moduleRole('laporan_anestesi')">
							<a href="javascript:;" @click="toMenu(rowEdit,'RanapLaporanAnestesi')" class="btn_cta_persalinan">
								{{rowEdit.arano_done_laporan_anestesi == 'Y' ? 'Ubah ':'Buat '}}
								Laporan Anestesi </a>
						</div>
						<div class="col-md-6 mb-2" v-if="moduleRole('laporan_induksi')">
							<a href="javascript:;" @click="toMenu(rowEdit,'RanapLaporanPraInduksi')" class="btn_cta_persalinan">
								{{rowEdit.arano_done_laporan_prainduksi == 'Y' ? 'Ubah ':'Buat '}}
								Assesmen Pra Induksi </a>
						</div>
						<div class="col-md-6 mb-2"
							v-if="moduleRole('catatan_ruang_pulih') && (rowEdit.arano_operasi_end && rowEdit.arano_operasi_end_by) && rowEdit.arano_status >= sOpTerjadwal">
							<a href="javascript:;" @click="toMenu(rowEdit,'RanapCatatanRuangPulih')" class="btn_cta_persalinan">
								{{rowEdit.arano_done_ruang_pulih == 'Y' ? 'Ubah ':'Buat '}}
								Catatan Ruang Pemulihan </a>
						</div>
					</div>
				</b-modal>


				<validation-observer ref="VFormSubmitJadwalOperasi">
					<b-modal v-model="openJadwalOperasi" ok-title="Submit" @ok.prevent="submitJadwalOperasi" size="lg"
						no-close-on-backdrop class="text-center" :title="'Jadwalkan Operasi'">
						<table class="table table-sm table-bordered">
							<thead>
								<tr>
									<th colspan="6">Informasi Pasien</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td colspan="2">
										<div class="result_tab">
											<h4>Nama</h4>
											<p>{{rowEdit.ap_fullname||"-"}}</p>
										</div>
									</td>
									<td colspan="2">
										<div class="result_tab">
											<h4>Tanggal Lahir / Umur</h4>
											<p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
										</div>
									</td>
									<td colspan="2">
										<div class="result_tab">
											<h4>Jenis Kelamin</h4>
											<p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
										</div>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<div class="result_tab">
											<h4>Ruangan</h4>
											<p>{{rowEdit.mranb_name||"-"}}</p>
										</div>
									</td>
									<td colspan="2">
										<div class="result_tab">
											<h4>Kamar / No. Tempat Tidur / Kelas</h4>
											<p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
										</div>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<div class="result_tab">
											<h4>Jenis Cara Bayar</h4>
											<p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
										</div>
									</td>
									<td colspan="2">
										<div class="result_tab">
											<h4>Alergi</h4>
											<ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
												<li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
													{{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
												</li>
											</ul>
											<span v-else>Tidak ada Alergi</span>
										</div>
									</td>
									<td colspan="2">
										<div class="result_tab">
											<h4>Diagnosa Masuk</h4>
											<p>{{rowEdit.arano_diagnosa||"-"}}</p>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="row mt-2">
							<div class="col-md-12 mt-2">
								<div class="card">
									<div class="card-header bg_head_panel">
										<h6 class="card-title font-weight-semibold">Jadwalkan Operasi</h6>
									</div>
									<div class="card-body p-3">
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label>Ruang Operasi <strong class="text-danger">*</strong></label>
													<v-select placeholder="Ruang Operasi" v-model="rowEdit.arano_ruang_operasi"
														:options="mRuangOperasi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
													<VValidate name="Ruang Operasi" v-model="rowEdit.arano_ruang_operasi"
														:rules="{required: 1}" />
												</div>
											</div>
										</div>

										<div class="row">
											<div class="col-md-3">
												<div class="form-group">
													<label>Waktu Mulai Operasi<strong class="text-danger">*</strong></label>
													<div class="input-group">
														<datepicker input-class="form-control transparent" placeholder="Tanggal Mulai Operasi"
															class="my-datepicker" calendar-class="my-datepicker_calendar"
															v-model="rowEdit.arano_tanggal_operasi_start">
														</datepicker>
														<div class="input-group-append calendar-group">
															<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
														</div>
													</div>

													<VValidate name="Tanggal Mulai Operasi" v-model="rowEdit.arano_tanggal_operasi_start"
														:rules="{required: 1}" />
												</div>

											</div>

											<div class="col-md-3">
												<div class="form-group">
													<label class="opacity-0">Jam Mulai Operasi<strong class="text-danger">*</strong></label>
													<div class="input-group">
														<div class="input-group-prepend">
															<span class="input-group-text"><i class="icon-calendar22"></i></span>
														</div>
														<vue-timepicker manual-input format="HH:mm" input-class="form-control"
															v-model="rowEdit.arano_jam_operasi_start">
														</vue-timepicker>
													</div>

													<VValidate name="Jam Mulai Operasi" v-model="rowEdit.arano_jam_operasi_start"
														:rules="{required: 1}" />
												</div>
											</div>


											<div class="col-md-3">
												<div class="form-group">
													<label>Tanggal Berakhir Operasi<strong class="text-danger">*</strong></label>
													<div class="input-group">
														<datepicker input-class="form-control transparent" placeholder="Tanggal Mulai Operasi"
															class="my-datepicker" calendar-class="my-datepicker_calendar"
															v-model="rowEdit.arano_tanggal_operasi_end">
														</datepicker>
														<div class="input-group-append calendar-group">
															<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
														</div>
													</div>

													<VValidate name="Tanggal Berakhir Operasi" v-model="rowEdit.arano_tanggal_operasi_end"
														:rules="{required: 1}" />
												</div>
											</div>

											<div class="col-md-3">
												<div class="form-group">
													<label class="opacity-0">Jam Berakhir Operasi<strong class="text-danger">*</strong></label>
													<div class="input-group">
														<div class="input-group-prepend">
															<span class="input-group-text"><i class="icon-calendar22"></i></span>
														</div>
														<vue-timepicker manual-input format="HH:mm" input-class="form-control"
															v-model="rowEdit.arano_jam_operasi_end">
														</vue-timepicker>
													</div>
													<VValidate name="Jam Berakhir Operasi" v-model="rowEdit.arano_jam_operasi_end"
														:rules="{required: 1}" />
												</div>
											</div>


											<div class="col-md-4">
												<div class="form-group">
													<label>Perawat Asisten<strong class="text-danger">*</strong></label>
													<v-select placeholder="Perawat Asisten" v-model="rowEdit.arano_perawat_asisten"
														:options="mPerawatBedah" label="text" multiple :clearable="true" :reduce="v=>v.value">
													</v-select>
													<VValidate name="Perawat Asisten" v-model="rowEdit.arano_perawat_asisten"
														:rules="{required: 1}" />
												</div>
											</div>


											<div class="col-md-4">
												<div class="form-group">
													<label>Perawat Instrument<strong class="text-danger">*</strong></label>
													<v-select placeholder="Perawat Instrument" v-model="rowEdit.arano_perawat_instrumen"
														:options="mPerawatBedah" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
													<VValidate name="Perawat Instrument" v-model="rowEdit.arano_perawat_instrumen"
														:rules="{required: 1}" />
												</div>
											</div>

											<div class="col-md-4">
												<div class="form-group">
													<label>Perawat Anestesi<strong class="text-danger">*</strong></label>
													<v-select placeholder="Perawat Anestesi" v-model="rowEdit.arano_perawat_anastesi"
														:options="mPerawatBedah" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
													<VValidate name="Perawat Anestesi" v-model="rowEdit.arano_perawat_anastesi"
														:rules="{required: 1}" />
												</div>
											</div>

											<div class="col-md-4">
												<div class="form-group">
													<b-form-checkbox value="Y" unchecked-value="N" v-model="rowEdit.arano_is_delay"
														class="form-check-input-styled" name="radio-inline-left">
														Terdapat Penundaan Operasi
													</b-form-checkbox>
												</div>
											</div>

											<div class="col-md-4" v-if="rowEdit.arano_is_delay == 'Y'">
												<div class="form-group">
													<label>Keterangan </label>
													<b-form-textarea v-model="rowEdit.arano_alasan_delay" rows="4" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-modal>
				</validation-observer>


				<b-modal v-model="openInformConsent" size="xl" hide-footer class="text-center"
					:title="'Persetujuan Tindakan Kedokteran (Informed Consent)'">
					<div class="card-body">
						<div class="row row-gutter">
							<div class="col-md-12 col-12 mb-2" v-if="(informConcent||[]).length">
								<b-form-group class="mb-0">
									<label>Dokter Pelaksana</label>
									<v-select placeholder=" -- Pilih Dokter Pelaksana -- " v-model="rowEdit.dokter_pelaksana"
										:options="mDPJP" label="text" :reduce="v=>v.text"></v-select>
								</b-form-group>
							</div>
							<div class="col-md-4" v-for="(v,k) in (informConcent||[])" :key="k">
								<div class="wrap_line mb-0 h-100">
									<div class="mb-2">
										<h3 class="pb-0">[{{v.aranic_created_on}}] {{v.aranic_name||"-"}}
	                  <span v-if="v.is_cancel" :class="`badge badge-danger text-white`">Dibatalkan</span>
										</h3>
									</div>
									<div>
										<a href="javascript:;" @click="openTemplateIC(v)"
											class="btn alpha-success border-success text-success-800 mb-1">
											<b><i class="icon-download"></i></b>
											<span class="ml-2">Unduh Template Dokumen</span>
										</a>
										<Uploader @input="uploadDokStatus(v,k)" v-model="v.aranic_file" isDocument type="doc_only" />
									</div>

									<span class="badge badge-primary" v-if="v.aranic_created_on == 'PetugasRadiologi'">
										Radiologi
									</span>
									<span class="badge badge-primary" v-else>
										Dokter
									</span>

									<div class="d-flex align-items-center justify-content-between mt-2">
										<span v-if="v.aranic_status == 'DONE' || v.aranic_file" class="badge badge-success">Selesai</span>
										<span v-else class="badge badge-info">Unggah TTD</span>

										<div>
											<a v-if="v.aranic_file" :href="uploader(v.aranic_file)" target="_blank"
												class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700"
												v-b-tooltip.hover.right.noninteractive title="Unduh Dokumen"><i class="icon-download"></i></a>

											<a href="javascript:;" @click="openInformConsentDetail(v)" data-toggle="modal"
												class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700"
												v-b-tooltip.hover.right.noninteractive title="Lihat Detail"><i class="icon-eye"></i></a>
										</div>
									</div>

									<span :class="v.aranic_is_pasien_setuju == 'Y' ? 'badge badge-success':'badge badge-danger'">
									{{v.aranic_is_pasien_setuju == 'Y' ? 'Tindakan Disetujui':'Tindakan Ditolak'}} Oleh 
									{{v.aranic_persetujuan_tindakan == 'P' ? 'Pasien':'Penanggung Jawab'}}
									</span>
								</div>
							</div>
							<div class="col-md-4" v-if="!(informConcent||[]).length">
								<p>Tidak ada Data Inform Consent</p>
							</div>
						</div>
					</div>
				</b-modal>

				<validation-observer ref="VFormModal">
					<b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template"
						title="Unduh Template Dokumen" size="lg">
						<table class="table table-bordered table-sm text-uppercase">
							<tbody>
								<tr>
									<td colspan="3" class="bg-primary">Data Pasien</td>
								</tr>
								<tr>
									<td width="33%">
										<div class="result_tab">
											<h4>Nama Pasien</h4>
											<p>{{rowEdit.ap_fullname||"-"}}</p>
										</div>
									</td>
									<td width="33%">
										<div class="result_tab">
											<h4>Usia</h4>
											<p>{{rowEdit.ap_usia||"-"}}</p>
										</div>
									</td>
									<td width="33%">
										<div class="result_tab">
											<h4>Alamat</h4>
											<p>{{rowEdit.ap_address||"-"}}</p>
										</div>
									</td>
								</tr>
								<!-- == 'PJ'-->
								<template v-if="rowIC.aranic_persetujuan_tindakan == 'PJ'">
									<tr>
										<td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
									</tr>
									<tr>
										<td width="33%">
											<div class="result_tab">
												<h4>Nama Penanggung Jawab</h4>
												<b-form-input v-model="rowIC.aranic_nama_pj" type="text" name="NamaPenanggung"
													id="NamaPenanggung" class="form-control" placeholder="Nama Penanggung Jawab" />
												<VValidate name="Nama Penanggung Jawab" v-model="rowIC.aranic_nama_pj" :rules="{required: 1}" />
											</div>
										</td>
										<td width="33%">
											<div class="result_tab">
												<h4>Usia Penanggung Jawab</h4>
												<b-form-input v-model="rowIC.aranic_usia_pj" type="text" name="UsiaPenanggung" id="UsiaPenanggung"
													class="form-control" placeholder="Usia Penanggung Jawab" />
												<VValidate name="Usia Penanggung Jawab" v-model="rowIC.aranic_usia_pj" :rules="{required: 1}" />
											</div>
										</td>
										<td width="33%">
											<div class="result_tab">
												<h4>Alamat Penanggung Jawab</h4>
												<b-form-input v-model="rowIC.aranic_address_pj" type="text" name="alamat" id="alamat"
													class="form-control" placeholder="Alamat Penanggung Jawab" />
												<VValidate name="Alamat Penanggung Jawab" v-model="rowIC.aranic_address_pj"
													:rules="{required: 1}" />
											</div>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</b-modal>
				</validation-observer>


				<b-modal v-model="openPopupIC" title="Data Tindakan Anestesi" size="md" ok-only ok-title="Tutup">
					<OperasiInformConsent />
				</b-modal>

			</div>
		</div>
	</div>
	<div v-else>
		<template v-if="typeMenu == 'KajianPraAnestesi'">
			<KajianPraAnestesi />
		</template>
		<template v-if="typeMenu == 'RanapCeklistAnestesi'">
			<RanapCeklistAnestesi />
		</template>
		<template v-if="typeMenu == 'RanapCeklistKeselamatanAnestesi'">
			<RanapCeklistKeselamatanAnestesi />
		</template>
		<template v-if="typeMenu == 'RanapLaporanAnestesi'">
			<RanapLaporanAnestesi />
		</template>
		<template v-if="typeMenu == 'RanapLaporanPraInduksi'">
			<RanapLaporanPraInduksi />
		</template>
		<template v-if="typeMenu == 'RanapCatatanRuangPulih'">
			<RanapCatatanRuangPulih />
		</template>
	</div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'		

    import KajianPraAnestesi from './KajianPraAnestesi.vue'
    import RanapCeklistAnestesi from './RanapCeklistAnestesi.vue'
    import RanapCeklistKeselamatanAnestesi from './RanapCeklistKeselamatanAnestesi.vue'
    import RanapLaporanAnestesi from './RanapLaporanAnestesi.vue'
    import RanapLaporanPraInduksi from './RanapLaporanPraInduksi.vue'
		import OperasiInformConsent from './OperasiInformConsent.vue'
		import RanapCatatanRuangPulih from './RanapCatatanRuangPulih.vue'
    import Datepicker from 'vuejs-datepicker'
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'


    const moment = require('moment')

    
    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,Datepicker,VueTimepicker,KajianPraAnestesi,RanapCeklistAnestesi,RanapCeklistKeselamatanAnestesi,RanapLaporanAnestesi,RanapLaporanPraInduksi,OperasiInformConsent,RanapCatatanRuangPulih
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isDoc(){
                return this.$route.query.isDoc
            },
            dokterOperator(){
              let dokter = []
              for(let i = 0; i < (this.rowEdit.arano_dokter_operator||[]).length; i++){
                dokter.push(this.rowEdit.arano_dokter_operator[i]['dokter'])
              }
              return dokter
            },
            typeMenu(){
              return this.$route.query.type
            }
        },
        data(){
            return {
                idKey:'aranres_id',
                datePickerConfig: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(moment().add(6, 'days')),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(moment().add(6, 'days')),
                },
                mRuangOperasi: [],
                mPerawatBedah: [],
                mDPJP : [],
                rowEdit: {},
                openJadwalOperasi: false,
                operatorUtama: null,
                openPemeriksaan: false,
                mOperasiStatus: [],
                openKajianOperasi: false,

                informConcent: [],
                dataPJ: {},
                openInformConsent: false,
                rowIC: {},
                openTemplateICModal: false,
                openModalICDetail: false,

				openPopupIC: false,
            }
        },
        mounted(){
            this.filter.dpjp = 'ALL'
            
            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            toInformConsentInput(row){
                this.rowEdit = row
                this.rowEdit.arano_inform_consent_anestesi = (this.rowEdit.arano_inform_consent_anestesi||[])
                if(!(this.rowEdit.arano_inform_consent_anestesi||[]).length){
                    this.rowEdit.arano_inform_consent_anestesi.push({
                        nama_tindakan: row.arano_jenis,
                        id_tindakan: null,
												is_cancel: false
                    })
                }
                this.openPopupIC = true
            },
          jadwalkanOperasi(row){
            let data = row
            data.type = 'get-data'
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+'RanapOperasiPerawatBedah',
                {data: data}, 
                "POST"
            ).then(res=>{
              let resp = res.data
              this.loadingOverlay = false   
              this.openJadwalOperasi = true  
              this.rowEdit = resp.row
              this.operatorUtama = resp.row.arano_dokter_operator_utama
            })  
          },
          submitJadwalOperasi(){
            this.$refs['VFormSubmitJadwalOperasi'].validate().then(success=>{
              if(success){
                let data = this.rowEdit
                data.type = 'jadwalkan-operasi'
                       
                Gen.apiRest(
                    "/do/"+'RanapOperasiPerawatBedah',
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.loadingOverlay = false
                    this.$socket.emit('refresh_data_jadwal_operasi',{})
                    this.$swal({
                        title: resp.message,
                        icon: "success",
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        if(result.value){
                          this.openJadwalOperasi = false
                          this.apiGet()
                        }
                    })
                }).catch(err => {
                  this.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })

              }
            })
          },

          doOpenKajianOperasi(row){
            this.rowEdit = row
            this.openKajianOperasi = true
          },
          toDetail(regID){
						this.$router.push({ name: 'RanapPasienOperasi', params: {pageSlug: regID}}).catch(()=>{})
					},
          toMenu(v, menu){
            this.$router.push(
            {
              name: this.modulePage, 
              params: {pageSlug : v.arano_id}, 
              query: {type : menu, regId: v.arano_aranr_id} 
            })
          },
          setSlide(){
              const responsiveTable = document.querySelector('.table-responsive')
              if(responsiveTable){
                  const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                  const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                  if(responsiveTableScroll){
                      // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                      Array.from(responsiveTableScrollActions).forEach(action => {
                          action.addEventListener('click', e => {
                              const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                
                              responsiveTable.scroll({
                                  left: scrollAmount,
                                  behavior: 'smooth'
                              })
                          })
                      })
                  }
              }
          },
          doResetData(){
              this.doReset()
              this.dateRange =  {
                startDate: new Date(moment().subtract(6, 'days')),
                endDate: new Date(moment().add(6, 'days')),
              }
              this.doConvertDate()
          },
          doRefreshData(){
              this.apiGet()
              window.scrollTo(0,0)
          },
          apiGetResume(){
              let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
              Gen.apiRest(
                  "/get/"+this.modulePage+'/resume', 
                  {
                  params: Object.assign({}, paramsQuery||{})
                  }
              ).then(res=>{
                  _.forEach(res.data, (v,k)=>{
                      this.$set(this, k, v)
                  })
              })
          },
          doConvertDate(){
              this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
              this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
          },
          doPageOne(){
              this.filter.page = 1
          },
          doFill(){
              this.doPageOne()
              this.doFilter()
          },
          updateValues(e){
              this.doConvertDate()
              this.doFill()
          },
          doSearch: _.debounce(function(){
              this.doPageOne()
              this.doFilter()
          },500),
          getConfigDynamic(master,value){
              let text = ''
              if(value){
                  let index = (master||[]).findIndex(x => x.value == value)
                  if(index !== -1){
                      text = master[index]['text']
                  }
              }
              return text
          },

          // inform consent 
          toInformConsent(row){
            let data = row
            data.type = 'get-data-inform-consent'
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+'RanapOperasiPPJP',
                {data: data}, 
                "POST"
            ).then(res=>{
              let resp = res.data
              this.informConcent  = resp.informConcent
              this.dataPJ = resp.dataPJ
              this.rowEdit = row
	            this.$set(this.rowEdit, 'dokter_pelaksana', this.rowEdit.dokter_pelaksana||row.bu_full_name)
              this.loadingOverlay = false
              this.openInformConsent = true
            })  
          },
          openTemplateIC(v){
            this.rowIC = v
            this.rowIC.aranic_nama_pj = this.rowIC.aranic_nama_pj||this.rowEdit.ap_fullname
            this.rowIC.aranic_address_pj = this.rowIC.aranic_address_pj||this.rowEdit.ap_address
						this.rowIC.aranic_usia_pj = this.rowIC.aranic_usia_pj||this.rowEdit.ap_usia_with_ket
						this.openTemplateICModal = true
					},
          uploadDokStatus(v,k=''){
            Gen.apiRest('/do/' + this.modulePage, {
              data: {
                  type: 'update-data-ic',
                  id: v.aranic_id,
                  aranic_file: v.aranic_file,
                  aranic_nama_pj: v.aranic_nama_pj,
                  aranic_address_pj: v.aranic_address_pj,
									aranic_usia_pj: v.aranic_usia_pj,
                  aranic_status: 'DONE'
              }
            }, 'POST').then(res => {
                this.informConcent[k]['aranic_status'] = 'DONE' 
            })
          },
          uploadDok(v,k=''){
            Gen.apiRest('/do/' + 'RanapOperasiPPJP', {
              data: {
                  type: 'update-data-ic',
                  id: v.aranic_id,
                  aranic_file: v.aranic_file,
                  aranic_nama_pj: v.aranic_nama_pj,
                  aranic_address_pj: v.aranic_address_pj,
									aranic_usia_pj: v.aranic_usia_pj,
              }
            }, 'POST').then(res => {
              if(k){
                  this.informConcent[k]['aranic_status'] = 'DONE' 
              }
            })
          },
          openInformConsentDetail(v){
              Gen.apiRest('/do/' + 'RanapOperasiPPJP', {
                  data: {
                    type: 'get-data-ic',
                    id: v.aranic_id,
                  }
              }, 'POST').then(res => {
                  this.rowIC = res.data.row 
                  this.openModalICDetail = true                   
              })
          },
          unduhtemplateIC(v){
            this.$refs['VFormModal'].validate().then(success => {
                if(success){
                    this.uploadDok(v)                
                    setTimeout(()=>{
                        this.openTemplateICModal = false
                        let data = {exptype: 'xlsx', type: "export", id: v.aranic_id, regID: this.rowEdit.arano_aranr_id, dokter_pelaksana: this.rowEdit.dokter_pelaksana}
                        let self = this
                        self.loadingOverlay = true
                        $.ajax({
                            type: "POST",
                            url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsentRanap'}?token=IXs1029102asoaksoas102901290`,
                            data: data,
                            cache: false,
                            xhrFields:{
                                responseType: 'blob'
                            },
                            success: data => 
                            {
                                self.loadingOverlay = false
                                var link = document.createElement('a')
                                link.href = window.URL.createObjectURL(data)
                                link.download = `${'Inform-consent'}-${this.rowEdit.ap_fullname}-${moment().format("YYYY-MM-DD")}-${this.rowEdit.aranr_reg_code}.pdf`
                                link.click()
                            },
                            fail: data => {
                                self.loadingOverlay = false
                                alert('Not downloaded')
                            }
                        })
                    },2000)
                    
                }
            })
          },
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
          $route(){
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
            
            setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
            this.openKajianOperasi = false
          }
        }
    }
</script>