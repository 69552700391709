<template>
  <div class="card border shadow-0 mb-0">
    <div class="bg-light card-header py-2">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 font-weight-semibold">{{title}}</h6>
    </div>
    </div>
    <div class="card-body">
    <div class="form-group mb-0">
        <div class="input-group">
        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
            <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                v-model="inputICD10"
                @search="filterICD10($event,0)"
                @input="selectICD10(inputICD10)"
                :options="mICD10" label="text"
                :clearable="true" :reduce="v=>v.value">
                
                <template slot="selected-option">
                <span v-b-tooltip.hover.right :title="inputICD10Name">
                    {{inputICD10Name||"-"}}
                </span>
                </template>
                
                <template slot="option" slot-scope="option">
                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                </template>
                <template #list-footer v-if="(mICD10||[]).length >= 1">
                <li class="list-paging">
                    <div class="d-flex align-items-center justify-content-between">
                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                        class="icon-arrow-left22 text-white"></i></b-button>
                    <small>Halaman {{mICD10[0].currentPage}} dari
                        {{ceilData(mICD10[0].totalRows/5)}}</small>
                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                        class="icon-arrow-right22 text-white"></i></b-button>
                    </div>
                </li>
                </template>
            </v-select>
        </div>
        </div>
    </div>
    </div>
    <table :id="'icd-10-op-'+type" :ref="'icd-10-op-'+type" class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
    <thead>
        <tr>
        <th>Item ICD-10 dipilih</th>
        <th width="64">Aksi</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="(v,k) in (dataICD10||[])" :key="k">
        <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
        <td>
            <a href="javascript:;" @click="removedataICD10(v.aranicd10o_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
            <i class="icon-trash-alt"></i>
            </a>
        </td>
        </tr>
    </tbody>
    </table>
    <div class="card-body py-2" v-else>
    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
    </div>
  </div>
</template>

<script>

import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

import $ from 'jquery'
const _ = global._

export default {
  extends: GlobalVue,
  props: {
    title: String,
    type: String,
    idOp: Number,
    pasienId: Number,
  },
  data() {
    return {
      menuPage: "RanapICD10Operasi",

      mICD10: [],
      dataICD10: [],
      inputICD10: null,
      riwayatICD10: [],
      openriwayatICD10: false,      
      selectedICD10: '',
      searchICD10: '',
      inputICD10Name: null,
      searchICD10Name: null,
    }
  },
  methods: {
    
    ceilData(value) {
        return Math.ceil(value)
    },

    apiGet(params = {}, page = 1){
      this.loadingOverlay = true
      let url = this.menuPage+'/'+this.idOp+'?typeOperasi='+this.type      
      Gen.apiRest(
        "/get/"+url
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
      }).catch(()=>{
        this.loadingOverlay = false
      })

      // master
      Gen.apiRest(
          "/get/"+this.menuPage+'/'+this.idOp+'?master=1'
      ).then(res=>{
          console.log(res.data)
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
      })
    },
    
    filterICD10: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD10 || []).length; i++) {
            if (this.dataICD10[i]['aranicd10o_icd_10']) {
                id.push(this.dataICD10[i]['aranicd10o_icd_10'])
            }
        }
        
        this.searchICD10Name = e

        Gen.apiRest('/do/' + this.menuPage, {
            data: {
                type: 'select-icd-10',
                search: e,
                id: id
            }
            }, 'POST').then(res => {
            this.mICD10 = res.data.data
        })
    }, 10),
    
    selectICD10(e) {
        let index = this.mICD10.findIndex(x => x.value == e)
        if (index !== -1) {
            this.inputICD10Name =  this.mICD10[index]['text']
            this.searchICD10Name = null
        }else{
            this.inputICD10Name =  null
        }
        this.addNewICD10()
    },
    changePageICD10(page, k, act, col) {
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
        }
        
        let data = {
            type: 'select-paging-icd-10',
            page: page,
            search: this.searchICD10Name
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.mICD10 = res.data.data
            this.getmICD10(this.dataICD10, k)
        })
    },

    addNewICD10() {
        if(this.inputICD10){
        // if (!this.row.isEdit) {
        let data = {
            aranicd10o_arano_id: this.idOp,
            aranicd10o_icd_10: this.inputICD10,
            aranicd10o_type: this.type,
            type: 'add-icd-10'
        }
        Gen.apiRest(
            "/do/" + this.menuPage, {
            data: data
            },
            "POST"
        ).then(res => {
            let resp = res.data
            this.dataICD10.push(resp.row)
            this.inputICD10 = null
        })
        // } else {
        //   let data = {
        //     aranicd10o_arano_id: this.idOp,
        //     aranicd10o_icd_10: this.inputICD10,
        //   }
        //   this.dataICD10.push(data)
        // }
        }
    },
    removedataICD10(id, k) {
        // if (!this.row.isEdit) {
        let data = {
        id: id,
        type: 'remove-icd-10'
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(() => {
        this.dataICD10.splice(k, 1)
        })
        // } else {
        //   this.dataICD10.splice(k, 1)
        // }
    },

    getmICD10(diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
      return k2 != k
      }), v2 => {
      return v2.api10_icd_10
      })
      return _.filter(this.mICD10, v2 => {
      return sldMenu.indexOf(v2.value) <= -1
      })
    },
    
    autoSaveICD10: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-icd-10',
        data: data
      }
      Gen.apiRest(
        "/do/" + this.menuPage, {
          data: dataPost
        },
        "POST"
      )
    }, 1000),
  },
  mounted() {
    this.apiGet()
  },
  watch: {  
    dataICD10: {
      handler(v) {
        this.autoSaveICD10(v)
      },
      deep: true
    },
  }
  
}

</script>