<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <div class="row align-items-center">
                <div class="col-md-auto">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <h5 class="card-title font-weight-semibold">Kajian Pra Sedasi/Anestesi</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-header">
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Pengkajian Sistem </h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="table-responsive">
                            <table class="table table-striped table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td style="width:30%">Hilangnya gigi</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_hilang_gigi"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_hilang_gigi == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_hilang_gigi_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Hilangnya gigi" 
                                      v-model="row.aranpa_ps_hilang_gigi" 
                                      :rules="toValidate(mrValidation.aranpa_ps_hilang_gigi)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_hilang_gigi == 'Y'"
                                      name="Jelaskan Hilangnya gigi" 
                                      v-model="row.aranpa_ps_hilang_gigi_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_hilang_gigi_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Masalah mobilisasi </td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_mobilisasi"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_mobilisasi == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_mobilisasi_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Masalah mobilisasi" 
                                      v-model="row.aranpa_ps_mobilisasi" 
                                      :rules="toValidate(mrValidation.aranpa_ps_mobilisasi)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_mobilisasi == 'Y'"
                                      name="Jelaskan Masalah mobilisasi" 
                                      v-model="row.aranpa_ps_mobilisasi_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_mobilisasi_text)"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>Leher pendek</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_leher_pendek"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_leher_pendek == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_leher_pendek_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Leher Pendek" 
                                      v-model="row.aranpa_ps_leher_pendek" 
                                      :rules="toValidate(mrValidation.aranpa_ps_leher_pendek)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_leher_pendek == 'Y'"
                                      name="Jelaskan Leher Pendek" 
                                      v-model="row.aranpa_ps_leher_pendek_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_leher_pendek_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batuk</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_batuk"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_batuk == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_batuk_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Batuk" 
                                      v-model="row.aranpa_ps_batuk" 
                                      :rules="toValidate(mrValidation.aranpa_ps_batuk)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_batuk == 'Y'"
                                      name="Jelaskan Batuk" 
                                      v-model="row.aranpa_ps_batuk_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_batuk_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Sesak Napas </td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_sesak"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_sesak == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_sesak_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Sesak Napas" 
                                      v-model="row.aranpa_ps_sesak" 
                                      :rules="toValidate(mrValidation.aranpa_ps_sesak)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_sesak == 'Y'"
                                      name="Jelaskan Sesak Napas" 
                                      v-model="row.aranpa_ps_sesak_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_sesak_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Baru saja menderita infeksi saluran napas atas </td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_infeksi_saluran_atas"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_infeksi_saluran_atas == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_infeksi_saluran_atas_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Infeksi saluran napas atas" 
                                      v-model="row.aranpa_ps_infeksi_saluran_atas" 
                                      :rules="toValidate(mrValidation.aranpa_ps_infeksi_saluran_atas)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_infeksi_saluran_atas == 'Y'"
                                      name="Jelaskan Infeksi saluran napas atas" 
                                      v-model="row.aranpa_ps_infeksi_saluran_atas_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_infeksi_saluran_atas_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Periode menstruasi tidak normal </td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_mens_tidak_normal"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_mens_tidak_normal == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_mens_tidak_normal_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Menstruasi tidak normal" 
                                      v-model="row.aranpa_ps_mens_tidak_normal" 
                                      :rules="toValidate(mrValidation.aranpa_ps_mens_tidak_normal)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_mens_tidak_normal == 'Y'"
                                      name="Jelaskan Menstruasi tidak normal" 
                                      v-model="row.aranpa_ps_mens_tidak_normal_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_mens_tidak_normal_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Stroke </td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_stroke"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_stroke == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_stroke_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Stroke" 
                                      v-model="row.aranpa_ps_stroke" 
                                      :rules="toValidate(mrValidation.aranpa_ps_stroke)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_stroke == 'Y'"
                                      name="Jelaskan Stroke" 
                                      v-model="row.aranpa_ps_stroke_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_stroke_text)"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="table-responsive">
                            <table class="table table-striped table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td style="width:30%">Sakit dada</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_sakit_dada"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_sakit_dada == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_sakit_dada_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Sakit Dada" 
                                      v-model="row.aranpa_ps_sakit_dada" 
                                      :rules="toValidate(mrValidation.aranpa_ps_sakit_dada)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_sakit_dada == 'Y'"
                                      name="Jelaskan Sakit Dada" 
                                      v-model="row.aranpa_ps_sakit_dada_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_sakit_dada_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Denyut jantung tidak normal </td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_denyut_jantung"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_denyut_jantung == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_denyut_jantung_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Denyut Jantung Tidak Normal" 
                                      v-model="row.aranpa_ps_denyut_jantung" 
                                      :rules="toValidate(mrValidation.aranpa_ps_denyut_jantung)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_denyut_jantung == 'Y'"
                                      name="Jelaskan Denyut Jantung Tidak Normal" 
                                      v-model="row.aranpa_ps_denyut_jantung_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_denyut_jantung_text)"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>Muntah </td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_muntah"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_muntah == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_muntah_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Muntah" 
                                      v-model="row.aranpa_ps_muntah" 
                                      :rules="toValidate(mrValidation.aranpa_ps_muntah)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_muntah == 'Y'"
                                      name="Jelaskan Muntah" 
                                      v-model="row.aranpa_ps_muntah_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_muntah_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Sulit kencing</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_sulit_kencing"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_sulit_kencing == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_sulit_kencing_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Sulit Kencing" 
                                      v-model="row.aranpa_ps_sulit_kencing" 
                                      :rules="toValidate(mrValidation.aranpa_ps_sulit_kencing)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_sulit_kencing == 'Y'"
                                      name="Jelaskan Sulit Kencing" 
                                      v-model="row.aranpa_ps_sulit_kencing_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_sulit_kencing_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Kejang</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_kejang"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_kejang == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_kejang_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Kejang" 
                                      v-model="row.aranpa_ps_kejang" 
                                      :rules="toValidate(mrValidation.aranpa_ps_kejang)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_kejang == 'Y'"
                                      name="Jelaskan Kejang" 
                                      v-model="row.aranpa_ps_kejang_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_kejang_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Sedang hamil</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_hamil"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_hamil == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_hamil_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Sedang Hamil" 
                                      v-model="row.aranpa_ps_hamil" 
                                      :rules="toValidate(mrValidation.aranpa_ps_hamil)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_hamil == 'Y'"
                                      name="Jelaskan Sedang Hamil" 
                                      v-model="row.aranpa_ps_hamil_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_hamil_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pingsan</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_pingsan"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_pingsan == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_pingsan_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Pingsan" 
                                      v-model="row.aranpa_ps_pingsan" 
                                      :rules="toValidate(mrValidation.aranpa_ps_pingsan)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_pingsan == 'Y'"
                                      name="Jelaskan Pingsan" 
                                      v-model="row.aranpa_ps_pingsan_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_pingsan_text)"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Obesitas</td>
                                  <td>
                                    <div class="d-flex" style="align-items: center;">
                                      <b-form-radio-group
                                        class="radio-inline"
                                        :options="Config.mr.yesNoOptV2"
                                        v-model="row.aranpa_ps_obesitas"
                                      />
                                      <div class="ml-2" v-if="row.aranpa_ps_obesitas == 'Y'">
                                        <b-form-input v-model="row.aranpa_ps_obesitas_text" style="width: 150px;" type="text" class="form-control" placeholder="Jelaskan"
                                          fdprocessedid="9kl27u" />
                                      </div>
                                    </div>
                                    <VValidate 
                                      name="Obesitas" 
                                      v-model="row.aranpa_ps_obesitas" 
                                      :rules="toValidate(mrValidation.aranpa_ps_obesitas)"
                                    />
                                    <VValidate 
                                      v-if="row.aranpa_ps_obesitas == 'Y'"
                                      name="Jelaskan Obesitas" 
                                      v-model="row.aranpa_ps_obesitas_text" 
                                      :rules="toValidate(mrValidation.aranpa_ps_obesitas_text)"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <div class="form-group">
                            <label for="spiNilai">Keterangan</label>
                            <textarea v-model="row.aranpa_ps_keterangan" name="spiNilai" id="spiNilai" rows="3" class="form-control"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="row">
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="perHub">Kesadaran<strong class="text-danger">*</strong></label>
                                    <b-form-textarea v-model="row.aranpa_ku_kesadaran" rows="3" class="form-control"></b-form-textarea>
                                    <VValidate 
                                      name="Kesadaran" 
                                      v-model="row.aranpa_ku_kesadaran" 
                                      :rules="toValidate(mrValidation.aranpa_ku_kesadaran)"
                                    />
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="perHub">Visus</label>
                                    <b-form-textarea v-model="row.aranpa_ku_visus" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="perHub">Faring</label>
                                    <b-form-textarea v-model="row.aranpa_ku_faring" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="perHub">Gigi Palsu</label>
                                    <b-form-textarea v-model="row.aranpa_ku_gigi_palsu" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>


                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="perHub">Keterangan</label>
                                    <b-form-textarea v-model="row.aranpa_ku_keterangan" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>


                                <div class="col-12 mb-3">
                                  <div class="card mb-2">
                                      <div class="card-header bg_head_panel">
                                      <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                                      </div>
                                      <div class="card-body p-3">
                                          <div class="row">
                                          <div class="col-md-4">
                                              <div class="form-group">
                                              <label>Tekanan Darah</label>
                                              <div class="input-group">
                                                  <b-form-input id="tekanan_darah_min" :formatter="number"
                                                  v-model="row.aranpa_ttv_tekanan_darah_min" type="text" name="name"
                                                  class="form-control" placeholder="Systole" />

                                                  <div class="input-group-append input-group-prepend">
                                                  <span class="input-group-text">/</span>
                                                  </div>

                                                  <b-form-input id="tekanan_darah_max" :formatter="number" placeholder="Diastole"
                                                  v-model="row.aranpa_ttv_tekanan_darah_max" type="text"
                                                  class="form-control" />
                                                  <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                                  </div>
                                              </div>
                                              
                                              <VValidate name="Tekanan Darah Min" v-model="row.aranpa_ttv_tekanan_darah_min"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_tekanan_darah_min)" />
                                              <VValidate name="Tekanan Darah Max" v-model="row.aranpa_ttv_tekanan_darah_max"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_tekanan_darah_max)" />
                                              </div>
                                          </div>

                                          <div class="col-md-5">
                                              <div class="form-group">
                                              <label>Nadi<span class="text-danger">*</span></label>
                                              <div class="form-row">
                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input style="max-width: 80px;" id="nadi" :formatter="number" v-model="row.aranpa_ttv_nadi"
                                                      type="text" class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">x/mnt</span>
                                                      </div>
                                                      <div class="input-group-append">
                                                          <div style="width: 150px;">
                                                              <v-select id="label" placeholder="Pilih Label" v-model="row.aranpa_ttv_label"
                                                              :options="Config.mr.StatusRegular" label="text"
                                                              :clearable="true" :reduce="v=>v.value"></v-select>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>
                                          
                                              <VValidate name="Nadi" v-model="row.aranpa_ttv_nadi"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_nadi)" />

                                              <VValidate name="Label" v-model="row.aranpa_ttv_label"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_label)" />
                                              </div>
                                          </div>

                                          <div class="col-md-3">
                                              <div class="form-group">
                                              <label>Gula Darah</label>
                                              <div class="form-row">
                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input id="gula_darah" :formatter="alphanum"
                                                      v-model="row.aranpa_ttv_gula_darah" type="text"
                                                      class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">mg/dL</span>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>

                                              <VValidate name="Gula Darah" v-model="row.aranpa_ttv_gula_darah"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_gula_darah)" />
                                              </div>
                                          </div>

                                          <div class="col-md-4">
                                              <div class="form-group">
                                              <label>Pernafasan<span class="text-danger">*</span></label>
                                              <div class="input-group">
                                                  <b-form-input id="pernafasan" :formatter="number"
                                                  v-model="row.aranpa_ttv_pernafasan" type="text" class="form-control" />
                                                  <div class="input-group-append"><span
                                                      class="input-group-text">x/mnt</span>
                                                  </div>
                                              </div>
                                                                  
                                              <VValidate name="Pernafasan" v-model="row.aranpa_ttv_pernafasan"
                                              :rules="toValidate(mrValidation.aranpa_ttv_pernafasan)" />
                                              
                                              </div>

                                          </div>

                                          <div class="col-md-3">
                                              <div class="form-group">
                                              <label>SPO2</label>
                                              <div class="form-row">
                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input id="spo2" :formatter="number" v-model="row.aranpa_ttv_spo2"
                                                      type="text" class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">%</span>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>
                                  
                                              <VValidate name="SPO2" v-model="row.aranpa_ttv_spo2"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_spo2)" />
                                              </div>
                                          </div>

                                          <div class="col-md-2">
                                              <div class="form-group">
                                              <label>Suhu<span class="text-danger">*</span></label>
                                              <div class="form-row">

                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input id="suhu" :formatter="number" v-model="row.aranpa_ttv_suhu"
                                                      type="text" class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">C</span>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>

                                              <VValidate name="Suhu" v-model="row.aranpa_ttv_suhu"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_suhu)" />
                                              </div>
                                          </div>

                                          <div class="col-md-2">
                                              <div class="form-group">
                                              <label>Berat Badan</label>
                                              <div class="form-row">

                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input id="weight" @input="hitungBMI()" :formatter="number"
                                                      v-model="row.aranpa_ttv_weight" type="text" class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">kg</span>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>

                                              <VValidate name="Berat Badan" v-model="row.aranpa_ttv_weight"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_weight)" />
                                              </div>
                                          </div>


                                          <div class="col-md-2">
                                              <div class="form-group">
                                              <label>Tinggi</label>
                                              <div class="form-row">

                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input @input="hitungBMI()"  :formatter="number"
                                                      v-model="row.aranpa_ttv_height" type="text" class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">cm</span>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>

                                              <VValidate name="Tinggi Badan" v-model="row.aranpa_ttv_height"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_height)" />
                                              </div>
                                          </div>

                                          <div class="col-md-3">
                                              <div class="form-group">
                                              <label>Lingkar Kepala</label>
                                              <div class="form-row">

                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input id="lingkar_kepala" @input="hitungBMI()" :formatter="number"
                                                      v-model="row.aranpa_ttv_lingkar_kepala" type="text"
                                                      class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">cm</span>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>

                                              <VValidate name="Lingkar Kepala" v-model="row.aranpa_ttv_lingkar_kepala"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_lingkar_kepala)" />
                                              </div>
                                          </div>

                                          <div class="col-md-3" v-if="row.ap_usia <= 15">
                                              <div class="form-group">
                                              <label>Luas Permukaan Tubuh Anak</label>
                                              <div class="input-group">
                                                  <b-form-input id="permukaan_anak" :formatter="number"
                                                  v-model="row.aranpa_ttv_luas_permukaan_anak" type="text"
                                                  class="form-control" />
                                                  <div class="input-group-append"><span
                                                      class="input-group-text">m<sup>2</sup></span></div>
                                              </div>
                                              </div>

                                          </div>

                                          <div class="col-md-3">
                                              <div class="form-group">
                                              <label>BMI</label>
                                              <div class="form-row">
                                                  <div class="col-md-12">
                                                  <div class="input-group">
                                                      <b-form-input disabled v-model="row.aranpa_ttv_bmi" type="text"
                                                      class="form-control" />
                                                      <div class="input-group-append">
                                                      <span class="input-group-text">m2</span>
                                                      </div>
                                                  </div>
                                                  </div>
                                              </div>
                                              <VValidate name="BMI" v-model="row.aranpa_ttv_bmi"
                                                  :rules="toValidate(mrValidation.aranpa_ttv_bmi)" />
                                              </div>
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="perHub">Paru-paru</label>
                                    <b-form-textarea v-model="row.aranpa_ku_paru" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="perHub">Jantung</label>
                                    <b-form-textarea v-model="row.aranpa_ku_jantung" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="perHub">Abdomen</label>
                                    <b-form-textarea v-model="row.aranpa_ku_abdomen" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="perHub">Ekstremitas</label>
                                    <b-form-textarea v-model="row.aranpa_ku_ekstremitas" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="perHub">Neurologi (bila bisa diperiksa)</label>
                                    <b-form-textarea v-model="row.aranpa_ku_neuorologi" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="perHub">Keterangan</label>
                                    <b-form-textarea v-model="row.aranpa_ku_keterangan_ttv" rows="3" class="form-control"></b-form-textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Diagnosis</h6>
                            </div>
                            <div class="card-body p-3">
                              <div>
                                <label for="kkeStatusMental">Klarifikasi berdasarkan ASA</label>
                                <div>
                                  <b-form-radio-group
                                    class="radio-inline"
                                    :options="Config.mr.mDiagnosisASA"
                                    v-model="row.aranpa_ku_diagnosis_asa"
                                  />
                                  <VValidate 
                                    name="Klarifikasi berdasarkan ASA" 
                                    v-model="row.aranpa_ku_diagnosis_asa" 
                                    :rules="toValidate(mrValidation.aranpa_ku_diagnosis_asa)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Rekomendasi Tindakan Anetesi yang Dipilih</h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label>Tipe Anestesi<small class="txt_mandatory">*</small></label>
                                        <v-select id="label" placeholder="-- Pilih Salah Satu --" v-model="row.aranpa_rek_tipe_anestesi"
                                        :options="Config.mr.mTipeAnestesi" label="text"
                                        :clearable="true" :reduce="v=>v.value"></v-select>
                                        <VValidate 
                                          name="Tipe Anestesi" 
                                          v-model="row.aranpa_rek_tipe_anestesi" 
                                          :rules="toValidate(mrValidation.aranpa_rek_tipe_anestesi)"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <!-- Jika Pilih Anastesi Umum-->
                                      <div v-if="row.aranpa_rek_tipe_anestesi == 'Anestesi Umum'">
                                        <b-form-checkbox-group
                                          v-model="row.aranpa_rek_tipe_anestesi_umum"
                                          :options="Config.mr.mAnasUmum"
                                        ></b-form-checkbox-group>
                                      </div>
                                      <!-- End-->

                                      <!-- Jika Pilih Regional Umum-->
                                      <div v-if="row.aranpa_rek_tipe_anestesi == 'Regional Anestesi'">
                                        <b-form-checkbox-group
                                          v-model="row.aranpa_rek_tipe_anestesi_regional"
                                          :options="Config.mr.mAnasRegional"
                                        ></b-form-checkbox-group>
                                      </div>
                                      <!-- End-->
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label>Puasa mulai</label>
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker input-class="form-control transparent" class="my-datepicker"
                                              calendar-class="my-datepicker_calendar"
                                              v-model="row.aranpa_puasa_mulai_date">
                                          </datepicker>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranpa_puasa_mulai_jam">
                                          </vue-timepicker>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label>Rencana tiba di UBS</label>
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker input-class="form-control transparent" class="my-datepicker"
                                              calendar-class="my-datepicker_calendar"
                                              v-model="row.aranpa_rencana_ubs_date">
                                          </datepicker>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranpa_rencana_ubs_jam">
                                          </vue-timepicker>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label>Rencana operasi</label>
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker input-class="form-control transparent" class="my-datepicker"
                                              calendar-class="my-datepicker_calendar"
                                              v-model="row.aranpa_rencana_operasi_date">
                                          </datepicker>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranpa_rencana_operasi_jam">
                                          </vue-timepicker>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                                        
                      <div class="row" v-if="Object.keys(dataPenunjang||{}).length">
                        <div class="col-12">
                            <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6>Hasil Pemeriksaan Lab</h6>
                                        <table class="table table-bordered table-striped table-sm patient-table">
                                            <thead>
                                            <tr>
                                                <th>Hasil Pemeriksaan </th>
                                                <th>Hasil</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v,k) in (dataPenunjang.dataHasilLab||[])" :key="k+'hasil'">
                                                <td>{{v.value}}</td>
                                                <td>
                                                    <div class="result_tab form-group mb-0">
                                                    <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="!(dataPenunjang.dataHasilLab||[]).length">
                                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-md-12 mt-3">
                                        <h6>Hasil Pemeriksaan Radiologi</h6>
                                        <table class="table table-bordered table-striped table-sm patient-table">
                                            <thead>
                                            <tr>
                                                <th>Hasil Pemeriksaan </th>
                                                <th>Hasil</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologi||[])" :key="k+'hasil'">
                                                <td>{{v.value}}</td>
                                                <td>
                                                    <div class="result_tab form-group mb-0">
                                                    <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="!(dataPenunjang.dataHasilRadiologi||[]).length">
                                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <h6>Hasil Pemeriksaan Lab Dokter</h6>
                                        <table class="table table-bordered table-striped table-sm patient-table">
                                            <thead>
                                            <tr>
                                                <th>Hasil Pemeriksaan </th>
                                                <th>Hasil</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v,k) in (dataPenunjang.dataHasilLabDokter||[])" :key="k+'hasil'">
                                                <td>{{v.value}}</td>
                                                <td>
                                                    <div class="result_tab form-group mb-0">
                                                    <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="!(dataPenunjang.dataHasilLabDokter||[]).length">
                                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-md-12 mt-3">
                                        <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                                        <table class="table table-bordered table-striped table-sm patient-table">
                                            <thead>
                                            <tr>
                                                <th>Hasil Pemeriksaan </th>
                                                <th>Hasil</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                                <td>{{v.value}}</td>
                                                <td>
                                                    <div class="result_tab form-group mb-0">
                                                    <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="!(dataPenunjang.dataHasilRadiologiDokter||[]).length">
                                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                      </div>

                    </div>
                    <div class="card-footer">
                      <div class="text-right">
                        <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                        <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      dataPenunjang: {},
      menuPage: 'RanapKajianPraAnestesi',
      patientData: {},
      loading: {
          patientInfo: false,
      },
    }
  },
  components:{ Datepicker,VueTimepicker,PatientInfo },
  mounted() {
    this.apiGet() 
    this.getPatientInfo()
  },
  methods: {
    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),
    
    hitungBMI(){
        if(this.row.aranpa_ttv_weight && this.row.aranpa_ttv_height){
            this.row.aranpa_ttv_bmi = this.row.aranpa_ttv_weight / ((this.row.aranpa_ttv_height/100)*(this.row.aranpa_ttv_height/100))
            this.row.aranpa_ttv_bmi = this.row.aranpa_ttv_bmi.toFixed(2) 
        }else{
            this.row.aranpa_ttv_bmi = null
        }
    },

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiDokterAnestesi'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    },
    tingkatKesadaranTotal(v){
        setTimeout(()=>{
            this.row.arankm_response_gcs = v
        },250)
    },
  }
}
</script>